import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import { Content } from '../components/styled'
import { ServicesQuery } from '../../graphql-types'
import Img from 'gatsby-image'

import { DEFAULT_SIZE } from '../Utils'
import MarkdownRenderer from '../components/MarkdownRenderer'

const services = ({
  location,
  data,
}: {
  location: Location
  data: ServicesQuery
}) => {
  return (
    <Layout location={location}>
      <Content>
        <div></div>
        {/* <About>{data.allContentfulAbout.edges[0].node.text.text}</About> */}
        <Services>
          <MarkdownRenderer
            className="about-text"
            html={
              data.allContentfulServices.edges[0].node.text.childMarkdownRemark
                .html
            }
          />
        </Services>
      </Content>
    </Layout>
  )
}

export default services

const Services = styled.div`
  /* font-size: ${DEFAULT_SIZE}rem; */

  a {
    text-decoration: underline;
  }

  .about-text *:first-of-type {
    margin-top: 0;
  }
`

export const pageQuery = graphql`
  query Services {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulServices {
      edges {
        node {
          text {
            childMarkdownRemark {
              html
              htmlAst
            }
          }
        }
      }
    }
  }
`
